import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Home } from '../home/Home'
import { AppRoutes } from './AppRoutes'
import { Error } from '../error/Error'
import { NotFound } from '../notfound/NotFound'
import { SignIn } from '../signin/SignIn'
import { SignUp } from '../signup/SignUp'
import { prodigiesTheme } from '../../theme/prodigiesTheme'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { ThemeProvider } from 'styled-components'
import { Welcome } from '../welcome/Welcome'
import { ConfirmEmail } from '../confirmEmail/ConfirmEmail'
import { ProtectedRoute } from './ProtectedRoute'
import { useSelector } from 'react-redux'
import { UnhandledError } from '../unhandledError/UnhandledError'
import { Memberships } from '../memberships/memberships'

export const IsUserLoggedIn = (): boolean => {
  const userLoggedInData = useSelector((state: any) => state.UserAuthenticationReducer.userLoggedInData)
  const userIsAuthenticated = userLoggedInData !== undefined && Object.keys(userLoggedInData).length > 0
  return userIsAuthenticated
}

export const AppRouter = () => {
  const isUserLoggedIn = IsUserLoggedIn()

  return (
    <MuiThemeProvider theme={prodigiesTheme}>
      <ThemeProvider theme={prodigiesTheme}>
        <BrowserRouter>
          <Routes>
            <Route path={AppRoutes.HOME} element={<Home />} />
            <Route
              path={AppRoutes.MEMBERSHIPS}
              element={
                <ProtectedRoute requireAuth={true} isAuthenticated={isUserLoggedIn} redirectPath={AppRoutes.SIGNIN}>
                  <Memberships />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppRoutes.SIGNIN}
              element={
                <ProtectedRoute requireAuth={false} isAuthenticated={isUserLoggedIn} redirectPath={AppRoutes.HOME}>
                  <SignIn />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppRoutes.SIGNUP}
              element={
                <ProtectedRoute requireAuth={false} isAuthenticated={isUserLoggedIn} redirectPath={AppRoutes.HOME}>
                  <SignUp />
                </ProtectedRoute>
              }
            />
            <Route path={AppRoutes.WELCOME} element={<Welcome />} />
            <Route
              path={AppRoutes.CONFIRM_EMAIL}
              element={
                <ProtectedRoute requireAuth={true} isAuthenticated={isUserLoggedIn} redirectPath={AppRoutes.SIGNIN}>
                  <ConfirmEmail />
                </ProtectedRoute>
              }
            />
            <Route path={AppRoutes.ERROR} element={<Error />} />
            <Route path={AppRoutes.UNHANDLED_ERROR} element={<UnhandledError />} />
            <Route path={AppRoutes.NOT_FOUND} element={<NotFound />} />
            <Route path={AppRoutes.OTHER} element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}
