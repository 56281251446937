import React from 'react'
import { AppRouter } from './components/router/AppRouter'

function App() {
  return (
    <div className="App">
      <div data-testid="AppRouterContainer">
        <AppRouter />
      </div>
    </div>
  )
}

export default App
