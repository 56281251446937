import React from 'react'
import Alert from '@mui/material/Alert'

interface CustomAlertProps {
  text: string
  color: 'red' | 'green'
}

export const CustomAlert: React.FC<CustomAlertProps> = ({ text, color }) => {
  const alertColor = color === 'red' ? 'error' : 'success'

  return <Alert severity={alertColor}>{text}</Alert>
}
