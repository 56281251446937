import { NAME_UPDATED, USER_LOGGEDIN, WEATHERFORCASTDATA_UPDATED } from '../actions/actions'

export function updateNameAction(text: string) {
  return { type: NAME_UPDATED, payload: text }
}

export function updateWeatherForcastDataAction(weatherForcastData: []) {
  return { type: WEATHERFORCASTDATA_UPDATED, payload: weatherForcastData }
}

export function signInUserDataAction(userData: []) {
  return { type: USER_LOGGEDIN, payload: userData }
}
