import { WEATHERFORCASTDATA_UPDATED } from '../actions/actions'

const initialStateWeatherForecastDataUpdateReducer = { weatherForecast: [] }
export function WeatherForecastDataReducer(state = initialStateWeatherForecastDataUpdateReducer, action: any) {
  switch (action.type) {
    case WEATHERFORCASTDATA_UPDATED:
      return { ...state, weatherForecast: action.payload }
    default:
      return state
  }
}
