import { createTheme } from '@mui/material/styles'

const breakpoints = {
  values: {
    xs: 300,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
}

const theme = createTheme({
  typography: {
    fontFamily: ['Cormorant', 'sans-serif'].join(','),
  },
  breakpoints: breakpoints,
})

export const prodigiesTheme = createTheme(theme, {
  breakpoints: breakpoints,
})
