import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../router/AppRoutes'
import { Spinner } from '../common/Spinner/Spinner'
import { useDispatch } from 'react-redux'
import {
  signInUserDataAction,
  updateNameAction,
  updateWeatherForcastDataAction,
} from '../../redux/actionsCreators/ActionCreators'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import axios from 'axios'

import { Header } from '../header/Header'
import { Navigation } from '../navigation/Navigation'
import { IsUserLoggedIn } from '../router/AppRouter'
import { CustomAlert } from '../common/CustomAlert/CustomAlert'

export const Home = () => {
  const myDispatch = useDispatch()
  const [showSpinner, setShowSpinner] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const navigate = useNavigate()
  const mySelector: any = useSelector((store: any) => store)
  const authData: any = useSelector((store: any) => store.UserAuthenticationReducer?.userLoggedInData?.authData)

  const fetchData = () => {
    setShowSpinner(true)
    const authToken = `Bearer ${authData.token}`

    axios
      .get(`${process.env.REACT_APP_API_URL}/WeatherForecast`, {
        headers: {
          Authorization: authToken,
        },
      })
      .then((response: any) => {
        myDispatch(updateWeatherForcastDataAction(response.data))
        setShowSpinner(false)
      })
      .catch((error: any) => {
        console.error(error)
        navigate('/error')
      })
  }

  const logout = () => {
    setShowSpinner(true)
    const authToken = `Bearer ${authData.token}`

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Auth/logout`,
        {},
        {
          headers: {
            Authorization: authToken,
          },
        },
      )
      .then((response: any) => {
        myDispatch(signInUserDataAction([]))
        setShowSpinner(false)
      })
      .catch((error: any) => {
        console.error(error)
        navigate('/error')
      })
  }

  const toggleAlert = () => {
    setShowAlert(!showAlert)
  }

  return (
    <div data-testid="HomePage">
      <Header />
      <Navigation />
      <Button variant="contained" onClick={toggleAlert}>
        {showAlert ? 'Hide Alert' : 'Show Alert'}
      </Button>
      {showAlert && <CustomAlert text={''} color={'green'} />}
      <div>Home Page</div>
      <br />
      <Button variant="contained" onClick={() => navigate(AppRoutes.NOT_FOUND)}>
        Go to not found page
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => navigate(AppRoutes.ERROR)}>
        Go to error page
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => fetchData()}>
        Fetch Data
      </Button>
      <br />
      <br />
      Test CDN Domain change
      <br />
      <br />
      {IsUserLoggedIn() && (
        <Button variant="contained" onClick={() => logout()}>
          Logout
        </Button>
      )}
      <br />
      <br />
      {mySelector.WeatherForecastDataReducer.weatherForecast.map((item: any) => (
        <div key={item.date}>
          <p>Date: {item.date}</p>
          <p>Temperature C: {item.temperatureC}</p>
          <p>Temperature F: {item.temperatureF}</p>
          <p>Summary: {item.summary}</p>
        </div>
      ))}
      <br />
      <br />
      <Button
        onClick={() => {
          myDispatch(updateNameAction('Kambitov ' + mySelector.NameReducer.name))
        }}
      >
        Update Name
      </Button>
      <br />
      <br />
      <p>
        The Current value of name in the store is:{' '}
        <b>
          <code>{mySelector.NameReducer.name}</code>
        </b>
      </p>
      {showSpinner && <Spinner />}
    </div>
  )
}
