import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../router/AppRoutes'
import { SPACINGS } from '../../theme/spacings'
import { Checkbox, FormControlLabel, Link } from '@mui/material'
import { COLORS } from '../../theme/colors'
import { signInUserDataAction } from '../../redux/actionsCreators/ActionCreators'
import { useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Spinner } from '../common/Spinner/Spinner'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import styled from 'styled-components'
import axios from 'axios'

const Container = styled.div`
  font-family: 'Cormorant';
  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 12px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100vh;
  ${(props) => props.theme.breakpoints.down('md')} {
    width: 80%;
    height: auto;
  }
`

const SignInLink = styled(Link)`
  && {
    color: black;
    ${(props) => props.theme.breakpoints.down('xs')} {
      font-size: ${SPACINGS.s12};
    }
  }
`
const Header = styled.h1`
  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: ${SPACINGS.s20};
  }
`
const Header2 = styled.h2`
  margin-bottom: ${SPACINGS.s24};
  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: ${SPACINGS.s16};
  }
`

const StyledButton = styled(Button)`
  && {
    background-color: ${COLORS.black};
    color: ${COLORS.white};
    margin: ${SPACINGS.s20} ${SPACINGS.s0};
    padding: ${SPACINGS.s12};
    justify-content: center;
    border-radius: 0;
    &:hover {
      color: ${COLORS.black};
      background-color: ${COLORS.lightgrey};
    }
    ${(props) => props.theme.breakpoints.down('xs')} {
      font-size: ${SPACINGS.s12};
    }
    &:disabled {
      background-color: grey;
      color: darkgrey;
    }
  }
`

const PointyTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 0;
  }
`

const SignUpForm = styled(Form)`
  width: 80%;
  maxwidth: 400px;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 70%;
  }
  ${(props) => props.theme.breakpoints.down('md')} {
    margin: 0 auto;
  }
`

const FormLabel = styled.label`
  font-size: ${SPACINGS.s16};
  margin-bottom: ${SPACINGS.s8};
  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: ${SPACINGS.s12};
  }
`

const SignUpErrorMessage = styled(ErrorMessage)`
  color: red;
`

const GridImage = styled(Grid)``

const GridForm = styled(Grid)``

export const SignUp = () => {
  const myDispatch = useDispatch()
  const [showSpinner, setShowSpinner] = useState(false)
  const [isPaydByAnInstitution, setIsPaydByAnInstitution] = useState(false)
  const navigate = useNavigate()
  const imagePath = '/signup-page-image.jpeg'

  let validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    parentConsent: Yup.boolean(),
    isPaidByAnInstitution: Yup.boolean(),
    institutionName: Yup.string(),
  })

  if (isPaydByAnInstitution) {
    validationSchema = Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
      parentConsent: Yup.boolean(),
      isPaidByAnInstitution: Yup.boolean(),
      institutionName: Yup.string().required('Institution name is required'),
    })
  }

  return (
    <Container data-testid="SignInPage">
      <Grid container spacing={4} style={{ minHeight: '100vh' }}>
        <GridImage
          item
          xs={12}
          md={6}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
        >
          <Image src={imagePath} alt="ProdigiesLogo" />
        </GridImage>
        <GridForm
          item
          xs={12}
          md={6}
          lg={6}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}
        >
          <Formik
            initialValues={{
              name: '',
              email: '',
              password: '',
              institutionName: '',
              couponCode: '',
              isPaidByAnInstitution: false,
              parentConsent: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setShowSpinner(true)
              axios
                .post(`${process.env.REACT_APP_API_URL}/api/auth/register`, {
                  name: values.name,
                  email: values.email,
                  password: values.password,
                  parentConsent: values.parentConsent,
                  accountType: 'Standart',
                  membership: 'Normal',
                  isPaidByAnInstitution: values.isPaidByAnInstitution,
                  institutionName: values.institutionName,
                  couponCode: values.couponCode,
                })
                .then((response: any) => {
                  setShowSpinner(false)
                  myDispatch(signInUserDataAction(response.data))
                  navigate(AppRoutes.WELCOME)
                })
                .catch((error: any) => {
                  console.error(error)
                  navigate('/error')
                })
            }}
          >
            {({ isSubmitting, values, setFieldValue, isValid, dirty }) => (
              <SignUpForm>
                <Header>PRODIGIES</Header>
                <Header2>Create your Prodigies account</Header2>
                <FormLabel htmlFor="name" aria-labelledby="name">
                  Name *
                </FormLabel>
                <Field type="text" id="name" name="name" as={PointyTextField} fullWidth />
                <SignUpErrorMessage name="name" component="div" />

                <br />
                <br />

                <FormLabel htmlFor="email" aria-labelledby="email">
                  Email Address *
                </FormLabel>
                <Field type="email" id="email" name="email" as={PointyTextField} fullWidth />
                <SignUpErrorMessage name="email" component="div" />

                <br />
                <br />

                <FormLabel htmlFor="password" aria-labelledby="password">
                  Password *
                </FormLabel>
                <Field type="password" id="password" name="password" as={PointyTextField} fullWidth />
                <SignUpErrorMessage name="password" component="div" />

                <FormControlLabel
                  control={<Field type="checkbox" as={Checkbox} name="parentConsent" />}
                  onChange={() => setFieldValue('parentConsent', !values.parentConsent)}
                  label="I am a parent/guardian and give consent."
                />

                <FormControlLabel
                  control={<Field type="checkbox" as={Checkbox} name="isPaidByAnInstitution" />}
                  onChange={() => {
                    setIsPaydByAnInstitution(!isPaydByAnInstitution)
                    setFieldValue('isPaidByAnInstitution', !values.isPaidByAnInstitution)
                  }}
                  label="Paid by an institution"
                />

                {values.isPaidByAnInstitution && (
                  <>
                    <Grid item xs={12}>
                      <FormLabel htmlFor="institutionName" aria-labelledby="institutionName">
                        Institution Name *
                      </FormLabel>
                      <Field type="text" id="institutionName" name="institutionName" as={PointyTextField} fullWidth />
                      <SignUpErrorMessage name="institutionName" component="div" />
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <FormLabel htmlFor="couponCode" aria-labelledby="couponCode">
                        Coupon Code
                      </FormLabel>
                      <Field type="text" id="couponCode" name="couponCode" as={PointyTextField} fullWidth />
                    </Grid>
                  </>
                )}
                <StyledButton className="styledButton" type="submit" fullWidth disabled={isSubmitting || !isValid || !dirty}>
                  CONTINUE WITH EMAIL
                </StyledButton>
                <Divider>or</Divider>
                <SignInLink component="button" variant="button" onClick={() => navigate(AppRoutes.SIGNIN)}>
                  Sign in
                </SignInLink>
              </SignUpForm>
            )}
          </Formik>
        </GridForm>
      </Grid>
      {showSpinner && <Spinner data-testid="Spinner" />}
    </Container>
  )
}
