import React from 'react'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../router/AppRoutes'

export const NotFound = () => {
  const navigate = useNavigate()
  return (
    <div data-testid="NotFoundPage">
      <div>Not Found Page</div>
      <br />
      <Button variant="contained" onClick={() => navigate(AppRoutes.HOME)}>
        Go to Home page
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => navigate(AppRoutes.ERROR)}>
        Go to Error page
      </Button>
    </div>
  )
}
