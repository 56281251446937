import React from 'react'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { AppRoutes } from '../router/AppRoutes'
import { SPACINGS } from '../../theme/spacings'
import { COLORS } from '../../theme/colors'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  text-align: center;
`

const TextContainer = styled.div`
  width: 500px;
  text-align: left;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: auto;
  }
`

const Header = styled.h2`
  line-height: 48px;
  color: ${COLORS.black};
  font-style: normal;
  font-weight: 500;
  ${(props) => props.theme.breakpoints.down('md')} {
    width: auto;
    font-size: 32px;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }
`

const Subheader = styled.h5`
  margin: ${SPACINGS.s20} ${SPACINGS.s0};
  line-height: ${SPACINGS.s32};
  font-size: ${SPACINGS.s24};
  width: 465px;
  font-style: normal;
  font-weight: 500;
  color: ${COLORS.black};
  ${(props) => props.theme.breakpoints.down('md')} {
    width: auto;
    font-size: 20px;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 18px;
    line-height: 24px;
  }
`

const Text = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: ${SPACINGS.s24};
  color: ${COLORS.black};
  margin-bottom: ${SPACINGS.s16};
  ${(props) => props.theme.breakpoints.down('md')} {
    width: auto;
    font-size: 16px;
    line-height: 20px;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: 0 auto;
  }
`

export const UnhandledError = () => {
  return (
    <Container data-testid="UnhandledErrorContainer">
      <Helmet>
        <title>Unhandled Error</title>
      </Helmet>
      <TextContainer>
        <Header data-testid="UnhandledErrorHeader">Oh no, you found a bug</Header>
        <Subheader data-testid="UnhandledErrorSubheader">
          Something has gone wrong which means we can’t load Prodigies.
        </Subheader>
        <Text data-testid="UnhandledErrorText">
          You have uncovered a problem our end. We have noted the error and will get it fixed as soon as we can. We’re sorry for
          the inconvenience.
        </Text>
        <Text data-testid="UnhandledErrorSecondaryText">If the problem persists, close your browser and try again.</Text>
      </TextContainer>
    </Container>
  )
}
