import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../router/AppRoutes'
import { SPACINGS } from '../../theme/spacings'
import { Link, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { COLORS } from '../../theme/colors'
import { useDispatch } from 'react-redux'
import { signInUserDataAction } from '../../redux/actionsCreators/ActionCreators'
import { Spinner } from '../common/Spinner/Spinner'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import styled from 'styled-components'
import * as Yup from 'yup'
import axios from 'axios'

const Container = styled.div`
  font-family: 'Cormorant';
  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 12px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100vh;
  ${(props) => props.theme.breakpoints.down('md')} {
    width: 80%;
    height: auto;
  }
`

const SignUpLink = styled(Link)`
  && {
    color: black;
    ${(props) => props.theme.breakpoints.down('xs')} {
      font-size: ${SPACINGS.s12};
    }
  }
`

const Header = styled.h1`
  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: ${SPACINGS.s20};
  }
`

const Header2 = styled.h2`
  margin-bottom: ${SPACINGS.s24};
  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: ${SPACINGS.s16};
  }
`

const StyledButton = styled(Button)`
  && {
    background-color: ${COLORS.black};
    color: ${COLORS.white};
    margin: ${SPACINGS.s20} ${SPACINGS.s0};
    padding: ${SPACINGS.s12};
    justify-content: center;
    border-radius: 0;
    &:hover {
      color: ${COLORS.black};
      background-color: ${COLORS.lightgrey};
    }
    ${(props) => props.theme.breakpoints.down('xs')} {
      font-size: ${SPACINGS.s12};
    }
    &:disabled {
      background-color: grey;
      color: darkgrey;
    }
  }
`

const PointyTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 0;
  }
`

const SignInForm = styled(Form)`
  width: 80%;
  maxwidth: 400px;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 70%;
  }
  ${(props) => props.theme.breakpoints.down('md')} {
    margin: 0 auto;
  }
`

const FormLabel = styled.label`
  font-size: ${SPACINGS.s16};
  margin-bottom: ${SPACINGS.s8};
  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: ${SPACINGS.s12};
  }
`

const GridImage = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.breakpoints.down('xs')} {
    justify-content: end;
  }
`

const GridForm = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const SignInErrorMessage = styled(ErrorMessage)`
  color: red;
`

export const SignIn = () => {
  const navigate = useNavigate()
  const imagePath = '/signin-page-image.jpeg'
  const myDispatch = useDispatch()
  const [showSpinner, setShowSpinner] = useState(false)
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg')) // 1200px
  const isMdUp = useMediaQuery(theme.breakpoints.up('md')) // 900px
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm')) // 600px
  const isXsUp = useMediaQuery(theme.breakpoints.up('xs')) // 300px

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is Required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  })

  return (
    <Container data-testid="SignInPage">
      <Grid container spacing={4} style={{ minHeight: '100vh' }}>
        <GridImage
          item
          xs={12}
          md={6}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
        >
          <Image src={imagePath} alt="ProdigiesLogo" />
        </GridImage>
        <GridForm
          item
          xs={12}
          md={6}
          lg={6}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}
        >
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setShowSpinner(true)
              axios
                .post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
                  email: values.email,
                  password: values.password,
                })
                .then((response: any) => {
                  myDispatch(signInUserDataAction(response.data))
                  setShowSpinner(false)
                  navigate(AppRoutes.HOME)
                })
                .catch((error: any) => {
                  console.error(error)
                  //navigate('/error')
                })
            }}
          >
            {({ isSubmitting, values, isValid, dirty }) => (
              <SignInForm>
                <Header>PRODIGIES</Header>
                <Header2>Sign in</Header2>

                <FormLabel htmlFor="email" aria-labelledby="email">
                  Email Address *
                </FormLabel>
                <Field type="email" name="email" as={PointyTextField} id="email" fullWidth />
                <SignInErrorMessage name="email" component="div" />
                <br />
                <br />
                <FormLabel htmlFor="password" aria-labelledby="password">
                  Password *
                </FormLabel>
                <Field type="password" name="password" as={PointyTextField} id="password" fullWidth />
                <SignInErrorMessage name="password" component="div" />

                <StyledButton type="submit" disabled={isSubmitting || !isValid || !dirty}>
                  CONTINUE WITH EMAIL
                </StyledButton>
                <Divider>or</Divider>
                <SignUpLink component="button" variant="button" onClick={() => navigate(AppRoutes.SIGNUP)}>
                  Sign up
                </SignUpLink>
              </SignInForm>
            )}
          </Formik>
        </GridForm>
      </Grid>
      {showSpinner && <Spinner data-testid="Spinner" />}
    </Container>
  )
}
