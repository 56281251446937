import React from 'react'
import styled, { keyframes } from 'styled-components'

const CenteredDiv = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinningImage = styled.img`
  animation: ${rotate} 2s linear infinite;
  border-radius: 50%;
  width: 50px;
  height: 50px;
`

export const Spinner = () => {
  return (
    <CenteredDiv data-testid="Spinner">
      <SpinningImage src={'/prodigiesLogo.jpeg'} alt="Loading" />
    </CenteredDiv>
  )
}
