import React from 'react'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../router/AppRoutes'
import axios from 'axios'
import { useSelector } from 'react-redux'

export const Memberships = () => {
  const navigate = useNavigate()
  const authData: any = useSelector((store: any) => store.UserAuthenticationReducer?.userLoggedInData?.authData)
  const authToken = `Bearer ${authData?.token}`
  const subscribe = (priceId: any) => {
    console.log(`Subscribed to ${priceId}`)

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/StripeCheckout/CreateStripeSession`,
        {
          priceId: priceId,
        },
        {
          headers: {
            Authorization: authToken,
          },
        },
      )
      .then((response: any) => {
        console.log('response')
        console.log(response.data.sessionUrl)
        window.location = response.data.sessionUrl
      })
      .catch((error: any) => {
        console.log(error)
        navigate('/error')
      })
  }

  return (
    <div data-testid="Memberships">
      <div>Memberships Page</div>
      <br />
      <Button variant="contained" onClick={() => subscribe(process.env.REACT_APP_ESSENTIAL_PLAN_YEARLY)}>
        Essential plan Yearly
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => subscribe(process.env.REACT_APP_ESSENTIAL_PLAN_MONTHLY)}>
        Essential plan Monthly
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => subscribe(process.env.REACT_APP_ADVANCED_PLAN_YEARLY)}>
        Advanced plan Yearly
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => subscribe(process.env.REACT_APP_ADVANCED_PLAN_MONTHLY)}>
        Advanced plan Monthly
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => subscribe(process.env.REACT_APP_PREMIUM_PLAN_YEARLY)}>
        Premium plan Yearly
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => subscribe(process.env.REACT_APP_PREMIUM_PLAN_MONTHLY)}>
        Premium plan Monthly
      </Button>
      <br />
      <br />
    </div>
  )
}
