import React from 'react'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../router/AppRoutes'

export const Error = () => {
  const navigate = useNavigate()

  const throwAnErrorFunction = async () => {
    // Throw Promise Regection Error
    await Promise.reject('Test error for Sentry')
  }

  const throwAnErrorFunction2 = () => {
    // Throw Error
    throw 'Test error for Sentry'
  }

  const throwAnErrorFunction3 = () => {
    // Object Undefined Property Error
    let obj: any
    console.log(obj.property)
  }

  const throwAnErrorFunction4 = () => {
    // Throw object Error
    const customError = { name: 'CustomError', message: 'Test error for Sentry' }
    throw customError
  }

  return (
    <div data-testid="ErrorPage">
      <div>Error Page</div>
      <br />
      <Button variant="contained" onClick={() => navigate(AppRoutes.HOME)}>
        Go to Home page
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => navigate(AppRoutes.NOT_FOUND)}>
        Go to Not Found page
      </Button>
      <br />
      <br />
      <Button variant="outlined" onClick={throwAnErrorFunction}>
        Test Sentry Errors
      </Button>
      <br />
      <br />
      <Button variant="outlined" onClick={throwAnErrorFunction2}>
        Test Sentry Errors 2
      </Button>
      <br />
      <br />
      <Button variant="outlined" onClick={throwAnErrorFunction3}>
        Test Sentry Errors 3
      </Button>
      <br />
      <br />
      <Button variant="outlined" onClick={throwAnErrorFunction4}>
        Test Sentry Errors 4
      </Button>
    </div>
  )
}
