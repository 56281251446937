import React from 'react'
import styled from 'styled-components'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

const HeaderBar = styled(AppBar)`
  && {
    background-color: transparent;
    box-shadow: none;
    padding: 10px 0;
  }
`

const LogoImage = styled.img`
  height: auto;
  width: 50%;
  max-width: 200px;
  margin: 0 auto;
  display: block;

  ${(props) => props.theme.breakpoints.up('md')} {
    width: 20%;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 15%;
  }
`

export const Header = () => {
  return (
    <HeaderBar position="static" data-testid="HeaderBarComponnet">
      <Toolbar data-testid="Toolbar">
        <LogoImage
          data-testid="LogoImage"
          src="https://prodigiesdevsta.blob.core.windows.net/images/prodigiesLogoWithText.jpg"
          alt="Prodigies Logo"
        />
      </Toolbar>
    </HeaderBar>
  )
}
