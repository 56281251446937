import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Spinner } from '../common/Spinner/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { signInUserDataAction } from '../../redux/actionsCreators/ActionCreators'

const ConfirmEmailContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: 'Cormorant', serif;
`
const Logo = styled.img`
  max-width: 150px; // You can adjust this as needed
  margin-bottom: 20px;
`

const ConfirmEmailHeader = styled.h1`
  margin-bottom: 20px;
`

const ConfirmButton = styled(Button)`
  margin-bottom: 20px;
  cursor: pointer;
  color: white !important;
  background-color: black !important;
`

const ConfirmationText = styled.p`
  margin-bottom: 30px;
`

export const ConfirmEmail = () => {
  const navigate = useNavigate()
  const myDispatch = useDispatch()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const userToken = urlParams.get('userToken')
  const [showSpinner, setShowSpinner] = useState(false)
  const user: any = useSelector((store: any) => store.UserAuthenticationReducer?.userLoggedInData?.userData)
  const authData: any = useSelector((store: any) => store.UserAuthenticationReducer?.userLoggedInData?.authData)

  const verifyEmail = () => {
    setShowSpinner(true)
    const authToken = `Bearer ${authData.token}`

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Auth/confirmEmail`,
        {
          userToken: userToken,
        },
        {
          headers: {
            Authorization: authToken,
          },
        },
      )
      .then((response: any) => {
        setShowSpinner(false)

        const updatedUser = {
          ...user,
          emailConfirmed: true,
        }

        myDispatch(signInUserDataAction(updatedUser))
        navigate('/')
      })
      .catch((error: any) => {
        console.log(error)
        navigate('/error')
      })
  }

  return (
    <ConfirmEmailContainer data-testid="ConfirmEmailContainer">
      <Logo src="prodigiesLogoWithText.jpg" alt="Logo" />
      <ConfirmEmailHeader data-testid="ConfirmEmailHeader">
        Please verify your email by clicking the button below
      </ConfirmEmailHeader>
      <ConfirmButton onClick={verifyEmail}>Confirm Your Email</ConfirmButton>
      <ConfirmationText>After successful confirmation you will be redirected to the home page.</ConfirmationText>
      {showSpinner && <Spinner data-testid="Spinner" />}
    </ConfirmEmailContainer>
  )
}
