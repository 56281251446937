import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { SPACINGS } from '../../theme/spacings'
import { COLORS } from '../../theme/colors'

const NavContainer = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: ${SPACINGS.s20} ${SPACINGS.s0};
`

const NavLink = styled(RouterLink)`
  && {
    color: ${COLORS.black};
    margin: ${SPACINGS.s4} ${SPACINGS.s8};
    font-family: 'Cormorant', serif;
    font-size: ${SPACINGS.s16};
    cursor: pointer;
    text-decoration: none;
    padding: ${SPACINGS.s4} ${SPACINGS.s8};

    &:hover {
      text-decoration: underline;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      font-size: ${SPACINGS.s12};
      margin: ${SPACINGS.s4} ${SPACINGS.s8};
      padding: ${SPACINGS.s4} ${SPACINGS.s8};
    }
  }
`

export const Navigation = () => {
  return (
    <NavContainer data-testid="NavContainer">
      <NavLink to="/">HOME</NavLink>
      <NavLink to="/about">ABOUT</NavLink>
      <NavLink to="/news">NEWS</NavLink>
      <NavLink to="/portfolios">PORTFOLIOS</NavLink>
      <NavLink to="/memberships">MEMBERSHIPS</NavLink>
      <NavLink to="/blog">BLOG</NavLink>
      <NavLink to="/contact">CONTACT</NavLink>
    </NavContainer>
  )
}
