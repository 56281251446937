import React from 'react'
import { Navigate } from 'react-router-dom'

interface ProtectedRouteProps {
  requireAuth: boolean
  isAuthenticated: boolean
  redirectPath: string
  children: any
}

export const ProtectedRoute = ({ requireAuth, isAuthenticated, redirectPath, children }: ProtectedRouteProps) => {
  if (requireAuth && !isAuthenticated) {
    // Redirect to the specified path if the user is not authenticated but authentication is required
    return <Navigate to={redirectPath} />
  } else if (!requireAuth && isAuthenticated) {
    // Redirect to the specified path if the user is authenticated but should only see this route when unauthenticated
    return <Navigate to={redirectPath} />
  }

  return children
}
