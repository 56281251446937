import { USER_LOGGEDIN } from '../actions/actions'

const initialStateDataUpdateReducer = { userAuthenticationData: [] }

export function UserAuthenticationReducer(state = initialStateDataUpdateReducer, action: any) {
  switch (action.type) {
    case USER_LOGGEDIN:
      return { ...state, userLoggedInData: action.payload }
    default:
      return state
  }
}
