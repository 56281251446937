import { NAME_UPDATED } from '../actions/actions'

const initialStateNameUpdateReducer = { name: 'Atanas' }

export function NameReducer(state = initialStateNameUpdateReducer, action: any) {
  switch (action.type) {
    case NAME_UPDATED:
      return { ...state, name: action.payload }
    default:
      return state
  }
}
