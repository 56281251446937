import React from 'react'
import styled from 'styled-components'

const WelcomeContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: 'Cormorant', serif;
`
const Logo = styled.img`
  max-width: 150px; // You can adjust this as needed
  margin-bottom: 20px;
`

const WelcomeHeader = styled.h1`
  margin-bottom: 20px;
`

const SecondaryHeader = styled.h3`
  margin-bottom: 20px;
`

const ConfirmationText = styled.p`
  margin-bottom: 30px;
`

export const Welcome = () => {
  return (
    <WelcomeContainer data-testid="WelcomeContainer">
      <Logo src="prodigiesLogoWithText.jpg" alt="Logo" />
      <WelcomeHeader data-testid="WelcomeHeader">Welcome, thank you for registering in Prodigies.</WelcomeHeader>
      <SecondaryHeader>Please confirm Your Email</SecondaryHeader>
      <ConfirmationText>
        We have sent an email to your inbox. Please click on the link in the email to verify your account.
      </ConfirmationText>
    </WelcomeContainer>
  )
}
