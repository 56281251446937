export const SPACINGS = {
  s0: '0px',
  s4: '4px',
  s8: '8px',
  s12: '12px',
  s16: '16px',
  s20: '20px',
  s24: '24px',
  s28: '28px',
  s32: '32px',
}
